import React from 'react';
import cl from 'classnames';
import { withNamespaces } from 'react-i18next';
import Link from '../Link';
import Api from '../../utils/api';
import { removeTags } from '../../utils/strings';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import SiteTitle from '../PageHeaderTitle';

import s from './Application.module.scss';

import img from '../../assets/img/applications/apple-macbook-pro-15.png';
import img2 from '../../assets/img/applications/apple-macbook-pro-15@2x.png';
import img3 from '../../assets/img/applications/apple-macbook-pro-15@3x.png';

import card10Img from '../../assets/img/applications/ik-ev-2.svg';
import card1Img from '../../assets/img/applications/nologs.svg';
import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card5Img from '../../assets/img/applications/global.svg';
import card6Img from '../../assets/img/applications/group-10.svg';
import card7Img from "../../assets/img/applications/kill-switch.svg";

export const ApplicationMacOs = withNamespaces()(({ t, gatsbyUrl, pythonUrl, i18n, brand }) => {
  const api = new Api({ gatsbyUrl, pythonUrl, i18n });

  return (
    <Application
      h1={t('page_applications:h1_macos')}
      text={t('page_applications:text_1_macos')}
      list={t('page_applications:list_macos')}
      img={{ img, img2, img3 }}
      img_alt={t('page_applications:image_alt_mac')}
      img_title={`${brand.name} ${t('page_applications:image_alt_mac')}`}
    >
      <SiteTitle brandName={brand.name} title={removeTags(t('page_applications:h1_macos'))} />
      <div className={s.buttons}>
        <Link className={cl(s.button)} to={api.macOSDownloadUrl()}>
          {t('page_applications:download_app')}
        </Link>
        <Link to={api.applicationMacOsGuideUrl()} className={cl(s.button, s.button_dark)}>
          {t('page_applications:setup_guides')}
        </Link>
      </div>
    </Application>
  );
});

export const ListOfFeaturesMacOs = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_10_title'), text: t('page_applications:card_10_text'), imgSrc: card10Img },
    { title: t('page_applications:card_1_title'), text: t('page_applications:card_1_text'), imgSrc: card1Img },
    { title: t('page_applications:card_7_title'), text: t('page_applications:card_7_text'), imgSrc: card7Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewMacOs = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_2')}
    author={t('page_applications:anonymous')}
  />
));
